<template>
    <section class="hero is-bold is-primary is-fullheight">
        <slot></slot>
        <div class="hero-body">
            <div class="container has-text-centered" style="max-width: 600px">
                <h1 class="title">Register</h1>
                <form>                
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-left has-text-white">First Name</label>
                                <input v-model="firstName" class="input" placeholder="John" pattern=".*" autocomplete="given-name">
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label has-text-left has-text-white">Last Name</label>
                                <input v-model="lastName" class="input" placeholder="Smith" pattern=".*" autocomplete="family-name">
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label has-text-left has-text-white">Email</label>
                        <input v-model="email" class="input" type="email" placeholder="john@email.com" pattern=".*" autocomplete="username">
                    </div>
                    <div class="field">
                        <label class="label has-text-left has-text-white">Password</label>
                        <input v-model="password1" class="input" type="password" placeholder="Password" pattern=".*" autocomplete="new-password">
                    </div>
                    <div class="field">
                        <label class="label has-text-left has-text-white">Confirm Password</label>
                        <input v-model="password2" class="input" type="password" placeholder="Confirm Password" pattern=".*" autocomplete="new-password">
                    </div>
                    <div class="field">
                        <button class="button" type="submit" @click="register">Let's Plan</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal" :class="modalActive">
            <div class="modal-background"></div>
                <div class="modal-content">
                    <div class="box">
                        <h3 class="title has-text-black">Email verification</h3>
                        <p>A verification email has been sent to you. Please click on the link it contains to verify your email address.</p>
                        <div class="has-text-centered">
                            <button class="button" @click="onModalOk()">OK</button>
                        </div>
                    </div>
                </div>
            <button class="modal-close is-large" aria-label="close" @click="onModalOk()"></button>
        </div>        
    </section>
</template>

<script>
import {authentication} from '../shared/authentication'

export default {
    name: "Register",
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password1: '',
            password2: '',
            modalActive: "",
        }
    },
    methods: {
        register: function(event) {
            event.preventDefault();
            if(this.password1 == this.password2) {
                authentication.register(this.firstName, this.lastName, this.email, this.password1, this.onLoginSuccess, this.onLoginError)
            }
            else {
                alert('Passwords do not match');
            }            
        },
        onLoginSuccess: function() {
            this.modalActive = "is-active"; 
        },
        onLoginError: function(err) {            
            alert("An error occured during registration: " + err.message);
        },
        onModalOk: function() {
            this.modalActive = ""; 
            window.location.href = 'signin';
        }        
    }
}
</script>

<style scoped>
.label {
    font-weight: 400;
}
</style>